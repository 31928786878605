.category-admin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px;
  border: 2px solid var(--skin-color);
}

.category-admin-heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.category-admin-form {
  border: 1px solid #ccc;
  padding: 20px;
  max-width: 400px;
  width: 100%;
}

.category-list {
  margin-top: 20px;
  width: 100%;
}

.category-list h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.category-list ul {
  list-style-type: none;
  padding: 0;
}

.category-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
}
