.error-page-container{
    display: flex;
    padding-top: 80px;
    height: calc(100vh);
    align-items: center;
}
.error-content{
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-weight: 900;
    color: var(--skin-color);
}

@media (max-width: 991px) {
    .error-content{
        font-size: 28px;
    }
}