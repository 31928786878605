.order-page {
  min-height: 100vh;
  background-color: var(--bg-black-900);
 
}

.order-page .container {
  margin: 0 auto;
margin-bottom: 64px;
}

.order-page .section-title {
  margin-bottom: 20px;
}

.order-summary-row,
.orders-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  gap: 20px;
}

.order-summary,
.order-item {
  background-color: var(--bg-black-100);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 400px;
}

.items{
    background-color: var(--bg-black-900);
    border-radius: 10px;
    padding: 5px;
    margin-bottom: 10px;
}

.order-item img {
  max-width: 100px;
  margin-bottom: 10px;
}

.order-summary h3,
.order-item h4 {
  font-family: 'Poppins', sans-serif;
  color: var(--text-black-900);
  margin-bottom: 10px;
}

.order-item h5{
    color: var(--text-black-900);
}

.order-summary p,
.order-item p {
  font-family: 'Roboto', sans-serif;
  color: var(--text-black-700);
  margin-bottom: 5px;
}

.collect-reward-button {
  background-color: var(--skin-color);
  color: #ffffff;
  border: none;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-top: 10px;
  font-weight: bold;
  border-radius: 5px;
}

.collect-reward-button:hover {
  background-color: var(--btn-hover-3);
}

.status-buttons button {
  background-color: var(--skin-color);
  color: #ffffff;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

.status-buttons button.active,
.status-buttons button:hover {
  background-color: var(--btn-hover-2);
}

.status-buttons span {
  color: red;
}
