.admin-orders {
  min-height: 100vh;
  background-color: var(--bg-black-900);
}

.admin-orders .container {
  margin: 0 auto;
}

.admin-orders .section-title {
  margin-bottom: 20px;
}

.status-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.status-buttons button {
  background-color: var(--skin-color);
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

.status-buttons button:hover {
  background-color: var(--btn-hover);
}

.orders-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.order-card {
  background-color: var(--bg-black-100);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 500px;
}

.order-card h4, .order-card p {
  font-family: 'Poppins', sans-serif;
  color: var(--text-black-900);
  margin-bottom: 10px;
}

.items-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.item-card {
  background-color: var(--bg-black-50);
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item-card img {
  max-width: 50px;
  margin-bottom: 10px;
}

.order-actions {
  margin-top: 20px;
}

.order-actions button {
  background-color: var(--skin-color-2);
  color:#ffffff;
  border: none;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

.order-actions button:hover {
  background-color: var(--btn-hover-2);
}

.delete-btn {
  background-color: #ff4d4d;
  color: white;
}

.delete-btn:hover {
  background-color: #cc0000;
}

