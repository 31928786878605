.checkout {
  min-height: 100vh;
  background-color: var(--bg-black-900);
}

.checkout .container {
  margin: 0 auto;
}

.checkout .section-title {
  margin-bottom: 20px;
}

.checkout .checkout-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.address-form,
.checkout-cart-summary {
  background-color: var(--bg-black-100);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 400px;
}

.address-form h3,
.checkout-cart-summary h3 {
  font-family: 'Poppins', sans-serif;
  color: var(--text-black-900);
  margin-bottom: 10px;
}

.address-form label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
  color: var(--text-black-700);
}

.address-form input {
  padding: 10px;
  border: 1px solid var(--text-black-700);
  border-radius: 5px;
}

.save-address-button,
.place-order-button {
    background-color: var(--skin-color-3);
  color: var(--pure-white);
  border: none;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-top: 10px;
  font-weight: bold;
  border-radius: 5px;
}

.place-order-button:hover {
  background-color: var(--btn-hover-3);
}

.edit-address-button {
  background-color: var(--skin-color-1);
  color: var(--pure-white);
  border: none;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-top: 10px;
  font-weight: bold;
  border-radius: 5px;
}

.edit-address-button:hover {
  background-color: var(--btn-hover-1);
}

.checkout-cart-summary h4 {
  font-family: 'Poppins', sans-serif;
  color: var(--text-black-900);
  margin-bottom: 10px;
}

.checkout-cart-summary p {
  font-family: 'Roboto', sans-serif;
  color: var(--text-black-700);
  margin-bottom: 5px;
}

.total-amount h3 {
  font-family: 'Poppins', sans-serif;
  color: var (--text-black-900);
}

@media (max-width: 991px) {
  .checkout .checkout-row {
    flex-direction: column;
  }
}
