.admin-dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

h1 {
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.nav-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #1d9200; /* Your skin color */
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nav-button:hover {
  background-color: #176100; /* Your hover color */
}

@media (min-width: 768px) {
  .button-container {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
  }
}
