.report-page {
  min-height: 100vh;
  background-color: var(--bg-black-900);
  color: var(--text-black-900);
  padding: 20px;
}

.report-page .container {
  margin: 0 auto;
}

.report-page .section-title {
  margin-left: 64px;
}

.report-summary {
  display: flex;
  gap: 20px;
  margin-left: 64px;
  margin-bottom: 32px;
}

.summary-item {
 width: 25%;
  background-color: var(--bg-black-100);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
}

.summary-item h3 {
  font-family: 'Poppins', sans-serif;
  color: var(--text-black-700);
  margin-bottom: 10px;
}

.summary-item p {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  color: var(--text-black-900);
}

.orders-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.order-card {
  background-color: var(--bg-black-100);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 400px;
}

.order-card h4, .order-card p {
  font-family: 'Poppins', sans-serif;
  color: var(--text-black-900);
  margin-bottom: 10px;
}
