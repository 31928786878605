.product-admin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px;
  border: 2px solid var(--skin-color);
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
}

.product-admin-heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.product-admin-form {
  width: 100%;
  max-width: 500px;
}

/* Edit Product CSS */

.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  margin: 10px;
  padding: 20px;
  width: 250px;
}

.product-item img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.product-info {
  text-align: center;
}

.product-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}


/* editProduct.css */
.edit-product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px;
  border: 2px solid var(--skin-color);
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
}

.edit-product-heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.edit-product-form {
  width: 100%;
  max-width: 500px;
}
