.category{
    min-height: calc(100vh - 80px);
    padding-bottom: 64px;
}
.category .container{
    margin: 0 auto;
}
.category .category-info-item {
    flex: 0 0 25%;
    max-width: 25%;
    text-align: center;
    margin-bottom: 30px;
    transition: background-color 0.3s, transform 0.3s; /* Added transition for smoothness */
}

.category-item {
    cursor: pointer;
    border-radius: 10px; /* Move the border-radius here for consistency */
    transition: background-color 0.3s, transform 0.3s; /* Added transition for smoothness */
}

.category-item:hover {
    background-color: var(--bg-black-50);
    transform: scale(1.05); /* Added transform for a slight zoom effect on hover */
}

.category .category-info-item h4 {
    font-size: 18px;
    font-weight: 700;
    color: var(--text-black-900);
}

.category .category-image {
    display: inline-block;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 10px;
    transition: transform 0.3s; /* Added transition for image smoothness */
}

.category .category-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 991px) {
    .category .category-info-item {
        flex: 0 0 50%;
        max-width: 50%;
    }
    
    .category .category-info-item h4 {
        font-size: 16px;
    }

    .category .category-image {
        width: 120px;
        height: 120px;
    }
}

@media (max-width: 767px) {
    .category .category-info-item {
        flex: 0 0 100%;
        max-width: 100%;
    }
    
    .category .category-image {
        width: 100px;
        height: 100px;
    }
}
