.cart {
  min-height: 100vh;
  background-color: var(--bg-black-900);
}

.cart .container {
  margin: 0 auto;
}

.cart .section-title {
  margin-bottom: 20px;
}

.cart .cart-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.cart .cart-item {
  background-color: var(--bg-black-100);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 10px;
  position: relative;
  width: 100%;
  max-width: 320px;
  transition: transform 0.3s ease;
}

.cart .cart-item img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 20px;
}

.cart .cart-item .item-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cart .cart-item h3 {
  font-family: 'Poppins', sans-serif;
  color: var(--text-black-900);
  margin: 10px 0;
  font-size: 1.2em;
}

.cart .cart-item p {
  font-family: 'Roboto', sans-serif;
  color: var(--text-black-700);
  font-size: 1.2em;
  margin: 5px 0;
}

.cart .cart-item .quantity {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.cart .cart-item .quantity input {
  width: 80px;
  text-align: center;
  font-size: 1.2em;
  border: 1px solid var(--skin-color);
  border-radius: 8px;
  margin: 0 10px;
  padding: 5px;
}

.cart .cart-item .quantity input:focus {
  outline: none;
  border-color: var(--btn-hover);
}

.cart .cart-item .quantity button {
  background-color: var(--skin-color);
  color: #ffffff;
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
  border-radius: 8px;
  font-size: 24px;
}

.cart .cart-item .quantity button:hover {
  background-color: var(--btn-hover);
}

.cart .cart-item .quantity span {
  display: inline-block;
  width: 50px;
  text-align: center;
  font-size: 1.2em;
}

.cart .cart-item .remove-item {
  background-color: var(--skin-color);
  color: #ffffff;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  font-weight: bold;
  position: absolute;
  top: 10px;
  right: 10px;
}

.cart .cart-item .remove-item:hover {
  background-color: var(--btn-hover-2);
}

.cart-checkout {
  margin-top: 20px;
  text-align: center;
}

.cart-checkout h3 {
  font-family: 'Poppins', sans-serif;
  color: var(--text-black-900);
  font-size: 1.5em;
  margin-bottom: 10px;
}

.cart-checkout-button {
  background-color: var(--skin-color);
  color: var(--pure-white);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
  font-size: 1.2em;
}

.cart-checkout-button:hover {
  background-color: var(--btn-hover);
}

@media (max-width: 568px) {
  .cart .cart-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .cart .cart-item img {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
