.empty-cart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
  text-align: center;
  color: var(--text-black-900);
  background-color: var(--bg-black-100);
}

.icon-wrapper {
  margin-bottom: 20px;
}

.icon {
  width: 60px;
  height: 60px;
  fill: var(--skin-color);
}

.empty-cart-container h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 1.8em;
  margin-bottom: 10px;
}

.empty-cart-container p {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2em;
  margin-bottom: 20px;
  color: var(--text-black-700);
}

.add-product-button {
  background-color: var(--skin-color);
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-size: 1.2em;
  transition: background-color 0.3s ease;
}

.add-product-button:hover {
  background-color: var(--btn-hover);
}
