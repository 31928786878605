.empty-order-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 80px);
  text-align: center;
  padding: 20px;
  background-color: var(--bg-black-100);
}

.empty-order-icon {
  color: var(--skin-color);
}

.empty-order-container h2 {
  font-size: 2rem;
  color: var(--text-black-900);
  margin-bottom: 10px;
}

.empty-order-container p {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2em;
  margin-bottom: 20px;
  color: var(--text-black-700);
}


.buy-now-button {
  background-color: var(--skin-color);
  color: #ffffff;
  border: none;
  padding: 15px 30px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.buy-now-button:hover {
  background-color: var(--btn-hover);
}
