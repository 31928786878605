.messages-container{
    max-width: 1300px;
    padding-top: 70px;
    margin: 0 auto;
}

.messages .messages-item {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 30px;
}

.messages .messages-item .messages-item-inner {
  background-color: var(--bg-black-100);
  border: 1px solid var(--bg-black-50);
  border-radius: 10px;
  padding: 20px 30px;
  text-align: left;
  transform: all 0.3s ease;
}

.messages .messages-item .messages-item-inner:hover {
  box-shadow: 0 0 20px rgba(48, 46, 77, 0.15);
}

.messages .messages-item .messages-item-inner .messages-items{
    display: block;
    padding: 10px;
}

.messages .messages-item .messages-item-inner .messages-items .messages-items-title{
    color: var(--skin-color);
    font-weight: bold;
}

.messages .messages-item .messages-item-inner .messages-items .messages-items-value{
    color: var(--text-black-900);
}