/* ComingSoon.css */
.coming-soon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.circle {
  position: relative;
  width: 200px;
  height: 200px;
  background-color: var(--skin-color); /* Yellow circle */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.text {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  z-index: 2;
}

.rotating-arcs {
  position: absolute;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  animation: rotateArcs 5s linear infinite;
}

.arc {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 8px solid #fff; /* White curved lines */
  border-radius: 50%;
  box-sizing: border-box;
  clip-path: polygon(100% 50%, 85% 85%, 50% 100%, 15% 85%, 0% 50%, 15% 15%, 50% 0%, 85% 15%);
  transform: rotate(0deg);
}

.arc:nth-child(2) {
  transform: rotate(120deg); /* Second arc at 120 degrees */
}

.arc:nth-child(3) {
  transform: rotate(240deg); /* Third arc at 240 degrees */
}

@keyframes rotateArcs {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
