.HeaderContainer {
  height: 80px;
}

.shadow-header {
  box-shadow: 0 0 20px rgba(48, 46, 77, 0.5);
}

.HeaderContent {
  position: fixed;
  display: flex;
  padding: 0 40px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  background-color: var(--bg-black-900);
  transition: box-shadow 0.3s ease;
  color: var(--text-black-900);
  z-index: 200;
}

.HeaderLogo {
  flex: 0 1 auto;
  display: flex;
  justify-content: center;
}

.HeaderLogo img {
  height: 50px;
}

.HeaderNavLinks {
  display: flex;
  align-items: center;
}

.HeaderLink {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

/* Style the link including icon on hover */
.HeaderLink:hover .nav-link,
.HeaderLink:hover .icon {
  color: var(--skin-color); /* Change text and icon color on hover */
}

.HeaderLink .nav-link {
  display: flex;
  align-items: center;
  font-weight: 600;
  color: var(--text-black-900);
  padding: 5px 15px;
  text-decoration: none;
}

.HeaderLink .icon {
  width: 25px;
  height: 25px;
  color: var(--text-black-900);
  margin-left: 6px;
}

.nav-link.active {
  color: var(--skin-color); /* Ensure active link color is set */
}

.HamburgerButton {
  display: none;
  cursor: pointer;
}

.MobileMenu {
  display: none;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-black-900);
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  z-index: 199;
}

.MobileMenu.show {
  display: flex;
}

@media (max-width: 867px) {
  .HeaderContent {
    justify-content: space-between;
  }

  .HeaderNavLinks {
    display: none;
  }

  .HeaderLogo {
    margin: 0;
  }

  .HamburgerButton {
    display: block;
    font-size: 24px;
  }

  .MobileMenu {
    top: 80px;
  }
}

.cart-count {
  position: absolute;
  top: 2px;
  right: 5px;
  background-color: var(--skin-color-2);
  color: var(--pure-white);
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 10px;
}

.logout-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: var(--bg-black-50);
  border: 1px solid var(--text-black-700);
  border-radius: 8px;
  z-index: 300;
}

.logout-dialog p {
  margin-bottom: 20px;
  color: var(--text-black-900);
}

.logout-dialog .btn {
  margin: 5px;
}

.logout-dialog .confirm-logout {
  background-color: var(--skin-color);
  color: var(--pure-white);
}

.logout-dialog .cancel-logout {
  background-color: var(--bg-black-100);
  color: var(--text-black-900);
}
