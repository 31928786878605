.product {
  min-height: 100vh;
  padding-bottom: 64px;
}

.product .container {
  margin: 0 auto;
}

.product .product-card {
    background-color: var(--bg-black-100);
    flex: 0 0 calc(20% - 40px);
    border-radius: 10px;
    box-shadow: 0 4px 8px #0000001a;
    overflow: hidden;
    text-align: center;
    transition: transform 0.3s ease;
    margin: 10px;
}

.product .product-card:hover {
  transform: translateY(-10px);
}

.product .product-card img {
  width: 100%;
  height: 150px;
  object-fit: fill;
  background-color: #ffffff;
}

.product .product-card h3 {
  font-family: 'Poppins', sans-serif;
  color: var(--text-black-900);
  margin: 10px 0;
}

.product-card p {
  font-family: 'Roboto', sans-serif;
  color: var(--text-black-700);
  font-size: 1.2em;
  margin: 10px 0;
}

.product .product-card button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--skin-color-3);
  color: #ffffff;
  border: none;
  width: 100%;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
  height: 35px;
}

.product .product-card button:hover {
  background-color: var(--btn-hover);
}
.product .product-card .view-cart {
  background-color: var(--skin-color-2);
}

.product .product-card .view-cart:hover {
  background-color: var(--btn-hover-2);
}

.product .product-card .view-cart span {
  margin-right: 8px; /* Add some space between the text and the icon */
}

.product .product-card .view-cart .icon {
  font-size: 16px; /* Adjust the size of the icon as needed */
  color: #ffffff;
  width: 20px; /* Ensure the icon has proper dimensions */
  height: 20px;
}


@media (max-width: 1200px) {
  .product .product-card {
    flex: 0 0 calc(33.333% - 40px);
  }
}

@media (max-width: 768px) {
  .product .product-card {
    flex: 0 0 calc(50% - 40px);
  }
}

@media (max-width: 576px) {
  .product .product-card {
    flex: 0 0 calc(100% - 40px);
  }
}
