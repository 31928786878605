.profile {
  min-height: 100vh;
  background-color: var(--bg-black-900);
}

.profile .container {
  margin: 0 auto;
}

.profile .section-title {
  margin-bottom: 20px;
}

.profile .profile-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.profile-details{
  text-align: center;
}
.username{
  font-weight: bold;
}

.profile-details,
.address-form {
  background-color: var(--bg-black-100);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 400px;
}

.profile-details h3,
.address-form h3 {
  font-family: 'Poppins', sans-serif;
  color: var(--text-black-900);
  margin-bottom: 10px;
}

.profile-details p,
.address-form label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  font-family: 'Roboto', sans-serif;
  color: var(--text-black-900);
}

.profile-image {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
}

.address-form input {
  padding: 10px;
  border: 1px solid var(--text-black-700);
  color: var(--text-black-900);
  background-color: var(--bg-black-900);
  border-radius: 5px;
}

.save-address-button,
.logout-button,
.edit-address-button,
.delete-address-button {
  background-color: var(--skin-color-3);
  color: #ffffff;
  border: none;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-top: 10px;
  font-weight: bold;
  border-radius: 5px;
}

.logout-button {
  background-color: var(--skin-color-5);
}

.save-address-button:hover {
  background-color: var(--btn-hover-3);
}

.logout-button:hover {
  background-color: var(--btn-hover-5);
}

.edit-address-button {
  background-color: var(--skin-color-1);
}

.edit-address-button:hover {
  background-color: var(--btn-hover-1);
}

.delete-address-button {
  background-color: var(--skin-color-4);
}

.delete-address-button:hover {
  background-color: var(--btn-hover-4);
}

@media (max-width: 991px) {
  .profile .profile-row {
    flex-direction: column;
  }
}
