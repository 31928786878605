.slider {
  position: relative;
  width: 100%;
}

@media (min-width: 1000px) {
  .slider {
  height: 55vh;
}
}

@media (max-width: 1000px) {
  .slider {
  height: 45vh;
}
}

@media (max-width: 768px) {
    .slider {
    height: 35vh;
  }
}

@media (max-width: 576px) {
    .slider {
    height: 25vh;
  }
}


.slider-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slider-slide.active {
  opacity: 1;
}

.slider-slide img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.slider-caption {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
}
